// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acabamentos-index-js": () => import("./../src/pages/Acabamentos/index.js" /* webpackChunkName: "component---src-pages-acabamentos-index-js" */),
  "component---src-pages-balancos-index-js": () => import("./../src/pages/Balancos/index.js" /* webpackChunkName: "component---src-pages-balancos-index-js" */),
  "component---src-pages-bancos-index-js": () => import("./../src/pages/Bancos/index.js" /* webpackChunkName: "component---src-pages-bancos-index-js" */),
  "component---src-pages-banquetas-index-js": () => import("./../src/pages/Banquetas/index.js" /* webpackChunkName: "component---src-pages-banquetas-index-js" */),
  "component---src-pages-chaises-index-js": () => import("./../src/pages/Chaises/index.js" /* webpackChunkName: "component---src-pages-chaises-index-js" */),
  "component---src-pages-corporativo-index-js": () => import("./../src/pages/Corporativo/index.js" /* webpackChunkName: "component---src-pages-corporativo-index-js" */),
  "component---src-pages-cortinas-persianas-index-js": () => import("./../src/pages/CortinasPersianas/index.js" /* webpackChunkName: "component---src-pages-cortinas-persianas-index-js" */),
  "component---src-pages-espreguicadeiras-index-js": () => import("./../src/pages/Espreguicadeiras/index.js" /* webpackChunkName: "component---src-pages-espreguicadeiras-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mesas-index-js": () => import("./../src/pages/Mesas/index.js" /* webpackChunkName: "component---src-pages-mesas-index-js" */),
  "component---src-pages-ombrelones-index-js": () => import("./../src/pages/Ombrelones/index.js" /* webpackChunkName: "component---src-pages-ombrelones-index-js" */),
  "component---src-pages-papeis-de-parede-index-js": () => import("./../src/pages/PapeisDeParede/index.js" /* webpackChunkName: "component---src-pages-papeis-de-parede-index-js" */),
  "component---src-pages-puffs-index-js": () => import("./../src/pages/Puffs/index.js" /* webpackChunkName: "component---src-pages-puffs-index-js" */),
  "component---src-pages-quem-somos-index-js": () => import("./../src/pages/QuemSomos/index.js" /* webpackChunkName: "component---src-pages-quem-somos-index-js" */),
  "component---src-pages-sofas-poltronas-index-js": () => import("./../src/pages/SofasPoltronas/index.js" /* webpackChunkName: "component---src-pages-sofas-poltronas-index-js" */),
  "component---src-pages-success-index-js": () => import("./../src/pages/Success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-vasos-vietnamitas-index-js": () => import("./../src/pages/VasosVietnamitas/index.js" /* webpackChunkName: "component---src-pages-vasos-vietnamitas-index-js" */)
}

